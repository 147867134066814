var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "d-flex align-items-center flex-column" },
        [
          _c("b-card", {
            staticClass: "mt-5",
            attrs: {
              title: "Carrier Management",
              "sub-title":
                "This service is for Internal Staff only and provides easy to use tools to manage Infinity's Carrier Stock."
            }
          }),
          _c("carriers-list", { staticClass: "mt-5" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
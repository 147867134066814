import { JsonData } from '@/helpers/api'

/**
 * This should be in the format of apiName: modelName
 */
export type ApiFieldDefinition = { [k: string]: string }

export function ucFirst (value: string): string {
  return `${value.charAt(0).toUpperCase()}${value.substr(1)}`
}

export function apiToModelTransformer (model: Model, data: JsonData, fieldDefinition: ApiFieldDefinition): void {
  for (const key in fieldDefinition) {
    if (
      Object.prototype.hasOwnProperty.call(fieldDefinition, key) &&
      Object.prototype.hasOwnProperty.call(data, key)
    ) {
      const fieldName = fieldDefinition[key]
      model[`set${ucFirst(fieldName)}`](data[key])
    }
  }
}

export function modelToApiTransformer (model: Model, fieldDefinition: ApiFieldDefinition): JsonData {
  const data: JsonData = {}

  for (const key in fieldDefinition) {
    if (Object.prototype.hasOwnProperty.call(fieldDefinition, key)) {
      const fieldName = fieldDefinition[key]
      data[key] = model[`get${ucFirst(fieldName)}`]()
    }
  }

  return data
}

export default abstract class Model {
  protected abstract fieldDefinition: ApiFieldDefinition

  /**
   * Takes a generic JSON data structure and sets the values onto the model
   * @param data
   */
  abstract fromApiTransformer(data: JsonData): Model

  /**
   * Should return data in a format that can be uses when creating or updating a model via the API.
   *
   * Return undefined if this method is not needed
   */
  abstract toApiTransformer(): JsonData | undefined

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any
}

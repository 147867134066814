var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.$store.state.isAuthenticated
        ? _c(
            "b-navbar",
            { attrs: { variant: "white", type: "light" } },
            [
              _c(
                "b-navbar-brand",
                { attrs: { href: "/" } },
                [_c("HubLogo", { attrs: { title: "Carrier Management" } })],
                1
              ),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item-dropdown",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function() {
                              return [_c("b-avatar")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3841813764
                      )
                    },
                    [
                      _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                        _vm._v("Sign Out")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
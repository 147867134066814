import Model, { ApiFieldDefinition, ucFirst } from '@/models/base'
import { JsonData } from '@/helpers/api'

export default class Carrier extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    carrierId: 'carrierId',
    carrierName: 'carrierName',
    carrierCode: 'carrierCode',
    providerName: 'providerName',
    carrierBillingCurrencyCode: 'carrierBillingCurrencyCode',
    carrierTimezone: 'carrierTimezone',
    peakStartTime: 'peakStartTime',
    peakEndTime: 'peakEndTime',
    billable: 'billable',
    quarantineDays: 'quarantineDays',
    allowNumberRecycle: 'allowNumberRecycle',
    customerCarrier: 'customerCarrier',
    disableNumberPool: 'disableNumberPool'
  }

  private carrierId = ''
  private carrierName = ''
  private carrierCode = ''
  private providerName = ''
  private carrierBillingCurrencyCode = ''
  private carrierTimezone = ''
  private peakStartTime = ''
  private peakEndTime = ''
  private billable = false
  private quarantineDays = 0
  private allowNumberRecycle = false
  private customerCarrier = false
  private disableNumberPool = false

  getCarrierId (): string {
    return this.carrierId
  }

  setCarrierId (value: string) {
    this.carrierId = value
  }

  getCarrierName (): string {
    return this.carrierName
  }

  setCarrierName (value: string) {
    this.carrierName = value
  }

  getCarrierCode (): string {
    return this.carrierCode
  }

  setCarrierCode (value: string) {
    this.carrierCode = value
  }

  getProviderName (): string {
    return this.providerName
  }

  setProviderName (value: string) {
    this.providerName = value
  }

  getCarrierBillingCurrencyCode (): string {
    return this.carrierBillingCurrencyCode
  }

  setCarrierBillingCurrencyCode (value: string) {
    this.carrierBillingCurrencyCode = value
  }

  getCarrierTimezone (): string {
    return this.carrierTimezone
  }

  setCarrierTimezone (value: string) {
    this.carrierTimezone = value
  }

  getPeakStartTime (): string {
    return this.peakStartTime
  }

  setPeakStartTime (value: string) {
    this.peakStartTime = value
  }

  getPeakEndTime (): string {
    return this.peakEndTime
  }

  setPeakEndTime (value: string) {
    this.peakEndTime = value
  }

  getBillable (): boolean {
    return this.billable
  }

  setBillable (value: boolean) {
    this.billable = value
  }

  getQuarantineDays (): number {
    return this.quarantineDays
  }

  setQuarantineDays (value: number) {
    this.quarantineDays = value
  }

  getAllowNumberRecycle (): boolean {
    return this.allowNumberRecycle
  }

  setAllowNumberRecycle (value: boolean) {
    this.allowNumberRecycle = value
  }

  getCustomerCarrier (): boolean {
    return this.customerCarrier
  }

  setCustomerCarrier (value: boolean) {
    this.customerCarrier = value
  }

  getDisableNumberPool (): boolean {
    return this.disableNumberPool
  }

  setDisableNumberPool (value: boolean) {
    this.disableNumberPool = value
  }

  get Action () {
    const action = !this.getDisableNumberPool() ? 'disable' : 'enable'

    return action.toUpperCase()
  }

  fromApiTransformer (data: JsonData | null): Carrier {
    const boolVars = ['disableNumberPool', 'customerCarrier', 'allowNumberRecycle', 'billable']

    if (data) {
      for (const [key, value] of Object.entries(data)) {
        const method = `set${ucFirst(key)}`

        if (typeof this[method] !== 'function') {
          continue
        }

        if (boolVars.indexOf(key) === -1) {
          this[method](value)
          continue
        }

        // handle numbers returned for boolean vars
        this[method](value === 1)
      }
    }

    return this
  }

  toApiTransformer (): JsonData | undefined {
    const disable = !this.getDisableNumberPool() ? 1 : 0

    return {
      toggleNumberPool: disable
    }
  }
}

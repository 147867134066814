var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _c("b-table", {
            attrs: {
              items: _vm.carriers,
              fields: _vm.fields,
              "sort-by": _vm.sortBy,
              "table-class": "mb-0",
              fixed: "",
              borderless: "",
              striped: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(billable)",
                  fn: function(data) {
                    return [
                      data.item.getBillable() ? _c("b-icon-check") : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(allowNumberRecycle)",
                  fn: function(data) {
                    return [
                      data.item.getAllowNumberRecycle()
                        ? _c("b-icon-check")
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(disableNumberPool)",
                  fn: function(data) {
                    return [
                      data.item.getDisableNumberPool()
                        ? _c("b-icon-check")
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(providerName)",
                  fn: function(data) {
                    return [
                      _vm._v(" " + _vm._s(data.value.toUpperCase()) + " ")
                    ]
                  }
                },
                {
                  key: "cell(disable)",
                  fn: function(data) {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.confirmAction",
                                  modifiers: { confirmAction: true }
                                }
                              ],
                              attrs: {
                                pressed: data.item.getDisableNumberPool(),
                                variant: data.item.getDisableNumberPool()
                                  ? "success"
                                  : "danger"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.doModal(data.item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  data.item.getDisableNumberPool()
                                    ? "Enable"
                                    : "Disable"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "cell(carrierName)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(data.item.carrierId) +
                          ". " +
                          _vm._s(data.item.carrierName) +
                          " "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3096972347
            )
          })
        : _vm.isLoading && _vm.notAllowed
        ? _c(
            "div",
            {
              staticClass:
                "vh-100 d-flex align-items-center justify-content-center mb-3"
            },
            [_c("b-spinner", { attrs: { label: "Loading..." } })],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.selectedCarrier,
              expression: "selectedCarrier"
            }
          ],
          attrs: { id: "confirmAction", title: "Confirm Action" },
          on: { ok: _vm.handleOk },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _vm._v(
                    " Action " + _vm._s(_vm.ucFirst(_vm.warning.action)) + " "
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-form", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-2 warning" },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "exclamation-triangle-fill",
                      scale: "4",
                      variant: "warning"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v("You are about to "),
                    _c("b", [_vm._v(_vm._s(_vm.warning.action))]),
                    _vm._v(" the tracking number pools for the carrier "),
                    _c("b", [_vm._v(_vm._s(_vm.warning.name))]),
                    _vm._v("! ")
                  ]),
                  _c("p", [
                    _vm._v(
                      "By ticking the checkbox and clicking OK below, the number stock for this carrier will be "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.warning.action + "D"))]),
                    _vm._v(" for customer use.")
                  ]),
                  _c(
                    "b-form-checkbox",
                    {
                      model: {
                        value: _vm.disclaimerChecked,
                        callback: function($$v) {
                          _vm.disclaimerChecked = $$v
                        },
                        expression: "disclaimerChecked"
                      }
                    },
                    [_c("small", [_vm._v("Tick to confirm")])]
                  ),
                  _vm.showDisclaimerMessage
                    ? _c("span", { staticClass: "text-muted e" }, [
                        _vm._v("** You must confirm the action **")
                      ])
                    : _vm._e(),
                  _vm.updateError
                    ? _c("span", { staticClass: "text-muted e" }, [
                        _vm._v(_vm._s(_vm.updateError))
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

















































































// eslint-disable-next-line import/no-unresolved
import { getFieldMeta, VOCAB_DEFAULT } from 'data-dictionary'
import { Component, Vue } from 'vue-property-decorator'
import Carrier from '@/models/carrier'
import Model from '@/models/base'
import Repository from '@/repos/base'
import { Api } from '@/helpers/api'
import { BvModalEvent } from 'bootstrap-vue'
import { AuthUtil } from '@/utils/auth'

@Component({})
export default class CarriersList extends Vue {
  fields = [
    {
      key: 'carrierName',
      label: getFieldMeta('carrier', 'carrierName', VOCAB_DEFAULT).label,
      sortable: true,
      thClass: 'w-20'
    },
    {
      key: 'carrierTimezone',
      label: getFieldMeta('carrier', 'carrierTimezone', VOCAB_DEFAULT).label,
      sortable: true,
      thClass: 'w-15'
    },
    {
      key: 'providerName',
      label: getFieldMeta('carrier', 'providerName', VOCAB_DEFAULT).label
    },
    {
      key: 'quarantineDays',
      label: getFieldMeta('carrier', 'quarantineDays', VOCAB_DEFAULT).label,
      tdClass: 'text-center',
      thClass: 'text-center'
    },
    {
      key: 'billable',
      label: getFieldMeta('carrier', 'carrierBillable', VOCAB_DEFAULT).label,
      thClass: 'w-8 text-center',
      tdClass: 'w-8 text-center'
    },
    {
      key: 'allowNumberRecycle',
      label: getFieldMeta('carrier', 'allowNumberRecycle', VOCAB_DEFAULT).label,
      thClass: 'w-8 text-center',
      tdClass: 'w-8 text-center'
    },
    {
      key: 'disableNumberPool',
      label: getFieldMeta('carrier', 'disableNumberPool', VOCAB_DEFAULT).label,
      thClass: 'w-8 text-center',
      tdClass: 'w-8 text-center'
    },
    {
      key: 'disable',
      label: ''
    }
  ]

  sortBy = 'name'

  isLoading = true
  notAllowed = true
  disclaimerChecked = false
  showDisclaimerMessage = false
  selectedCarrier: Carrier | null = null

  warning = {
    name: '',
    action: ''
  }

  updateError = ''

  carriers!: Carrier[] | Model[]

  private repo = new Repository(Api.CarrierManagementService, 'carriers', Carrier, 'result')

  reset () {
    this.isLoading = false
    this.notAllowed = true
    this.disclaimerChecked = false
    this.showDisclaimerMessage = false
    this.selectedCarrier = null
  }

  data () {
    return {
      carriers: []
    }
  }

  async created () {
    this.reset()
    await this.getCarriers()
  }

  async getCarriers () {
    try {
      this.isLoading = true
      this.carriers = await this.repo
        .fetch(0, 0)
      this.isLoading = false
    } catch (err) {
      this.notAllowed = false

      if (err.response?.status === 401) {
        await AuthUtil.logout()
        this.$store.commit('setAuthenticated', AuthUtil.isAuthenticated)

        return await this.$router.push('/login')
      }

      const message = 'Something went wrong, please try again'
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 5000,
        appendToast: true,
        solid: true,
        toaster: 'b-toaster-top-right'
      })
    }
  }

  async doSaveNumberPoolToggle (carrier: Carrier) {
    const [result] = await Promise.allSettled([
      this.repo.update(carrier, carrier.getCarrierId(), 'togglepool'),
      new Promise(resolve => setTimeout(resolve, 1000))
    ])

    if (result.status === 'fulfilled') {
      // Set the carrier value here as the put request returns a 204 response
      carrier.setDisableNumberPool(!carrier.getDisableNumberPool())
    }

    if (result.status === 'rejected' && result.reason) {
      this.updateError = result.reason.response.data.error
    }
  }

  async handleOk (bvModalEvt: BvModalEvent) {
    bvModalEvt.preventDefault()

    if (!this.disclaimerChecked) {
      this.showDisclaimerMessage = true
      setTimeout(() => {
        this.showDisclaimerMessage = false
      }, 5000)
      return
    }

    if (this.selectedCarrier !== null) {
      await this.doSaveNumberPoolToggle(this.selectedCarrier)
      this.$bvModal.hide('confirmAction')
      this.reset()
    }
  }

  doModal (carrier: Carrier) {
    this.selectedCarrier = carrier

    this.warning = {
      action: this.selectedCarrier.Action,
      name: this.selectedCarrier.getCarrierName().toUpperCase()
    }
  }

  ucFirst (value: string): string {
    return `${value.charAt(0).toUpperCase()}${value.substr(1).toLowerCase()}`
  }
}

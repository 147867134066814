import Vue from 'vue'
import Vuex from 'vuex'
import { AuthUtil } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: AuthUtil.isAuthenticated
  },
  mutations: {
    setAuthenticated (state, authenticated: boolean) {
      state.isAuthenticated = authenticated
    }
  },
  actions: {
  },
  modules: {
  }
})

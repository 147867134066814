





















import { Component, Vue } from 'vue-property-decorator'
import HubLogo from '@/components/layout/HubLogo.vue'
import { AuthUtil } from '@/utils/auth'

@Component({
  components: {
    HubLogo
  }
})
export default class App extends Vue {
  async logout () {
    try {
      await AuthUtil.logout()
      this.$store.commit('setAuthenticated', AuthUtil.isAuthenticated)
      await this.$router.push('/login')
    } catch (e) {
      this.$bvToast.toast('We were unable to log you out. Please try again!', {
        title: 'Error',
        variant: 'danger',
        autoHideDelay: 5000,
        appendToast: true,
        solid: true,
        toaster: 'b-toaster-top-right'
      })
    }
  }
}












import { Component, Vue } from 'vue-property-decorator'
import CarriersList from '@/components/CarriersList.vue'

@Component({
  components: {
    CarriersList
  }
})
export default class CMSView extends Vue {

}

import Model from '@/models/base'
import { JsonData, RequestMethod } from '@/helpers/api'
import { AuthUtil } from '@/utils/auth'
import axios, { AxiosRequestConfig } from 'axios'

export const DefaultLimit = 25

export type ApiParams = { [k: string]: string | number | string[] | number[] | null }

export default class Repository {
  protected api: string
  protected apiRoute: string
  protected Model: { new(): Model }
  protected accessProperty: string | null
  protected additionalParams: ApiParams = {}

  constructor (api: string, apiRoute: string, model: { new(): Model }, accessProperty: string | null) {
    this.api = api
    this.apiRoute = apiRoute
    this.Model = model
    this.accessProperty = accessProperty
  }

  async fetch (limit = DefaultLimit, offset = 0): Promise<Model[]> {
    const data = []
    const response = await axios({
      ...{
        url: `${this.api}/${this.apiRoute}`,
        method: RequestMethod.GET,
        params: {
          limit,
          offset,
          ...this.additionalParams
        }
      },
      ...this.requestConfig
    })

    let items = response.data

    // Traverse the data structure recursively to get to the level we need
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const traverse = (data: any): void => {
      for (const [key, value] of Object.entries(data)) {
        if (key === this.accessProperty) {
          items = value
          break
        }
        traverse(value)
      }
    }

    if (this.accessProperty !== '') {
      traverse(items)
    }

    if (response.data && items) {
      for (const item of items) {
        data.push(
          new this.Model().fromApiTransformer(item)
        )
      }
    }

    this.additionalParams = {}

    return data
  }

  async find (id: string | number): Promise<Model | null> {
    const response = await axios({
      ...{
        url: `${this.api}/${this.apiRoute}/${id}`,
        method: RequestMethod.GET,
        params: this.additionalParams
      },
      ...this.requestConfig
    })

    let data = response.data

    if (this.accessProperty !== null) {
      data = data[this.accessProperty]
    }

    if (!data) {
      return null
    }

    this.additionalParams = {}

    return new this.Model().fromApiTransformer(data)
  }

  async create (model: Model): Promise<JsonData | null> {
    let data: JsonData | { [k: string]: JsonData | undefined } | undefined = model.toApiTransformer()

    if (this.accessProperty !== null) {
      data = {
        [this.accessProperty]: data
      }
    }

    const response = await axios({
      ...{
        url: `${this.api}/${this.apiRoute}`,
        method: RequestMethod.POST,
        data,
        params: this.additionalParams
      },
      ...this.requestConfig
    })

    if (!response.data) {
      return null
    }

    this.additionalParams = {}

    return response.data
  }

  withParams (params: ApiParams) {
    this.additionalParams = params

    return this
  }

  protected get requestConfig (): AxiosRequestConfig | undefined {
    if (!AuthUtil.authenticatedHeaders) {
      return undefined
    }

    return {
      headers: AuthUtil.authenticatedHeaders[this.api]
    }
  }

  async update (model: Model, id: string, path: string | null): Promise<JsonData | null> {
    const data: JsonData | { [k: string]: JsonData | undefined } | undefined = model.toApiTransformer()

    let url = `${this.api}/${this.apiRoute}/${id}`

    if (path) {
      url += `/${path}`
    }

    const response = await axios.put(url, data, this.requestConfig)
    this.additionalParams = {}

    return response.data
  }
}















import { Component, Prop, Vue } from 'vue-property-decorator'

enum LogoViewBoxWidth { WithHub = 618, WithoutHub = 300 }

@Component
export default class HubLogo extends Vue {
  @Prop({ type: String }) title?: string

  get showHub (): boolean {
    return this.title === undefined
  }

  get viewBox (): string {
    if (this.showHub === false) {
      return `0 0 ${LogoViewBoxWidth.WithoutHub} 161.4`
    }

    return `0 0 ${LogoViewBoxWidth.WithHub} 161.4`
  }
}

























































import { Component, Vue } from 'vue-property-decorator'
import { AuthUtil, MfaError } from '@/utils/auth'

@Component({
})
export default class Login extends Vue {
  username = '';
  password = '';
  verificationCode = '';
  formState: boolean | null = null
  mfaState: boolean | null = null
  mfaRequired: boolean | null = null
  isLoading = false

  async onSubmit () {
    this.isLoading = true

    try {
      await AuthUtil.authenticate(this.username, this.password, this.mfaRequired ? this.verificationCode : null)
      this.$store.commit('setAuthenticated', AuthUtil.isAuthenticated)
      this.formState = null
      this.mfaState = null

      await this.$router.push('/')
    } catch (e) {
      const isMfaError = e instanceof MfaError
      if (this.mfaRequired && !isMfaError) {
        this.mfaState = false
        this.formState = true
      } else {
        this.formState = isMfaError
        this.mfaRequired = isMfaError
      }
    }

    this.isLoading = false
  }

  onReset () {
    this.username = ''
    this.password = ''
    this.verificationCode = ''
    this.formState = null
    this.mfaState = null
    this.mfaRequired = null
  }
}

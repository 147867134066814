var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hubLogo d-flex align-items-center" }, [
    _c(
      "svg",
      { attrs: { "aria-hidden": "true", viewBox: _vm.viewBox, height: "30" } },
      [
        _c("path", {
          staticClass: "stepOne color-infinityDarker",
          attrs: {
            d:
              "M52.1 158.1l71.2-113.8 12.6-20.2h-0.1l13-20.8c1.1-1.8 3.8-3.3 6-3.3h0.1 0c0 0 0 0 0 0l32.6 0c0 0 0 0 0 0h0.6c1.6 0 3.1 0.9 4.5 2.1 0.6 0.5 1 1.1 1.1 1.3l0.4 0.6c-0.6-0.9-1.3-1.8-2.6-1.8 -1.1 0-1.5 0.5-2.1 1.1 0 0 0 0 0 0l-67.8 108.3 -28.7 45.9c-0.2 0.3-0.4 0.6-0.6 0.8 -1.2 1.7-3.7 3.1-5.8 3.1H53.9C51.8 161.4 51 159.9 52.1 158.1z"
          }
        }),
        _c("path", {
          staticClass: "stepFour color-infinityDark",
          attrs: {
            d:
              "M0.9 77.4L8 66l26.1-41.9h0l13-20.8c0.4-0.6 1-1.2 1.6-1.7 0.7-0.5 1.4-0.9 2.2-1.2C51.6 0.2 52.4 0 53 0l32.5 0 0.8 0c2.1 0 4.8 1.5 6 3.3l0.4 0.6c-0.6-0.9-1.3-1.8-2.6-1.8 -1.8 0-2.8 1.4-3.4 2.5l0 0L74.4 24.1 41.2 77.4c-1.1 1.8-1.1 4.8 0 6.6l45.4 72.8 0 0c0.7 1.1 1.7 2.5 3.4 2.5 1.4 0 2.1-0.9 2.6-1.8l-0.4 0.6c-1.1 1.8-3.8 3.3-6 3.3H53c-2.1 0-4.8-1.5-6-3.3L0.9 84C-0.3 82.2-0.3 79.2 0.9 77.4"
          }
        }),
        _c("path", {
          staticClass: "stepThree color-infinity",
          attrs: {
            d:
              "M189.2 158.1L118 44.3l-12.6-20.2h0.1l-13-20.8c-1.1-1.8-3.8-3.3-6-3.3h-0.1 0c0 0 0 0 0 0H53.7c0 0 0 0 0 0H53c-1.6 0-3.5 0.8-4.8 2 -0.6 0.5-1 1.1-1.1 1.3l-0.4 0.6C47.3 3 48 2.1 49.4 2.1c1.1 0 1.9 0.5 2.5 1.2 0 0 0 0 0 0l67.8 108.3 28.7 45.9c0.2 0.3 0.4 0.6 0.6 0.8 1.2 1.7 3.7 3.1 5.8 3.1h32.6C189.5 161.4 190.3 159.9 189.2 158.1z"
          }
        }),
        _c("path", {
          staticClass: "stepTwo color-infinityLight",
          attrs: {
            d:
              "M240.2 77.4L233.1 66l-26.1-41.9h0L194 3.3c-0.4-0.6-1-1.2-1.6-1.7 -0.7-0.5-1.4-0.9-2.2-1.2C189.4 0.2 188.7 0 188 0h-32.5 -0.8c-2.1 0-4.8 1.5-6 3.3l-0.4 0.6c0.6-0.9 1.3-1.8 2.6-1.8 1.8 0 2.8 1.4 3.4 2.5l0 0 12.2 19.5 33.3 53.3c1.1 1.8 1.1 4.8 0 6.6l-45.4 72.8 0 0c-0.7 1.1-1.7 2.5-3.4 2.5 -1.4 0-2.1-0.9-2.6-1.8l0.4 0.6c1.1 1.8 3.8 3.3 6 3.3H188c2.1 0 4.8-1.5 6-3.3L240.2 84C241.3 82.2 241.3 79.2 240.2 77.4"
          }
        }),
        _vm.showHub
          ? _c("path", {
              staticClass: "color-txt",
              attrs: {
                d:
                  "M361.9 80.9v78.7c0 1.4-0.5 1.9-2.2 1.9h-7.3c-1.6 0-2.2-0.5-2.2-1.9V82.3c0-12.2-2.2-18.6-7.3-22.5 -6.2-4.7-13.2-6.6-27.7-6.6 -14.5 0-21.5 1.9-27.7 6.6 -5.1 4-7.3 10.3-7.3 22.5v77.3c0 1.4-0.5 1.9-2.2 1.9h-7.3c0 0 0 0 0 0h-0.7c-1.9 0-2.4-0.7-2.4-2.6V10.1c0-1.9 0.5-2.6 2.4-2.6h7.5c1.9 0 2.4 0.7 2.4 2.6v42.1c7-5.2 17.3-8 35.1-8 18.6 0 29.1 3 36.1 8.7C359.2 59 361.9 67.7 361.9 80.9M529.8 55.9h0.5c6.5-8.2 18.8-14.1 39.3-14.1C603 41.8 618 58.5 618 96v11c0 40.9-17.8 54-51.4 54 -35.3 0-48.4-13.6-48.4-41.1V1.9c0-1.4 0.8-1.9 1.9-1.9h7.5c1.3 0 2.2 0.5 2.2 1.9V55.9zM606.2 97c0-33.6-12.1-45.8-38-45.8 -27.7 0-38.5 12-38.5 33.6v34.5c0 23.7 12.9 32.4 37.1 32.4 28.8 0 39.3-11.3 39.3-46.5V97zM466.8 145.6c5.1-4 7.3-10.3 7.3-22.5V45.8c0-1.4 0.5-1.9 2.2-1.9h7.3c1.6 0 2.2 0.5 2.2 1.9v78.7c0 13.2-2.7 21.8-10.5 27.9 -7 5.6-17.5 8.7-36.1 8.7 -18.6 0-29.1-3.1-36.1-8.7 -7.8-6.1-10.5-14.8-10.5-27.9V45.8c0-1.4 0.5-1.9 2.2-1.9h7.3c1.6 0 2.2 0.5 2.2 1.9V123c0 12.2 2.2 18.6 7.3 22.5 6.2 4.7 13.2 6.6 27.7 6.6C453.6 152.2 460.6 150.3 466.8 145.6"
              }
            })
          : _vm._e()
      ]
    ),
    _vm.title
      ? _c("h5", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from 'axios'
import { Api } from '@/helpers/api'

enum StorageKey { AccessToken = 'accessToken', AuthUser = 'authUser', InstallationId = 'installationId' }

export class MfaError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'MfeError'
  }
}

export class AuthUtil {
  static async authenticate (username: string, password: string, verificationCode: string | null) {
    try {
      const requestData: { [key: string]: string } = { username, password }
      if (verificationCode !== null) {
        requestData.verificationCode = verificationCode
      }

      const response = await axios.post(`${Api.Hub}/security/anon/accesstoken`, requestData)

      if (response.data && response.data.accessToken) {
        localStorage.setItem(StorageKey.AccessToken, response.data.accessToken)
      }
    } catch (e) {
      const { data: { userProfile: { mfaEnabled = '0' } = {} } = {} } = e.response
      if (e.response.status === 403 && mfaEnabled === '1') {
        throw new MfaError(e.response.data)
      }

      throw new Error(e.response.data)
    }
  }

  static get authenticatedHeaders (): { [k: string]: { [k: string]: string | null } } | null {
    if (this.isAuthenticated === false) {
      return null
    }

    return {
      [Api.Hub]: {
        'x-auth-token': this.accessToken
      },
      [Api.CarrierManagementService]: {
        Authorization: `Token ${this.accessToken}`
      }
    }
  }

  static async logout () {
    try {
      const headers = AuthUtil.authenticatedHeaders

      if (headers) {
        await axios.delete(`${Api.Hub}/user/logout`, {
          headers: headers[Api.Hub]
        })
      }

      AuthUtil.invalidateSession()
    } catch (e) {
      if (e.response.status === 401) {
        AuthUtil.invalidateSession()
      } else {
        throw e
      }
    }
  }

  static invalidateSession () {
    localStorage.removeItem(StorageKey.AccessToken)
  }

  static get isAuthenticated (): boolean {
    return this.accessToken !== null
  }

  static get accessToken (): string | null {
    return localStorage.getItem(StorageKey.AccessToken)
  }
}
